import { withFooter } from '@/components/molecules/Footer'
import DeviceRegistration  from '@/components/template/Mfa/DeviceRegistration'
import React from 'react'

function MfaDeviceRegistration() {
    return (
        <>
            <DeviceRegistration
                displayMode={'forced'}
            />
        </>
    )
}

export default withFooter(MfaDeviceRegistration)
